import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import { invertHeader, resetHeader } from "../../actions"
import { connect } from "react-redux"

import WorkList from "./WorkList"
import ContentFilters from "../../components/ContentFilters"

class WorkArchive extends React.Component {
  constructor(props) {
    super(props)

    this.allWork = {}
    this.workCategoryName = this.props.pageContext.archiveType
    switch (this.props.pageContext.archiveType) {
      case `category`:
        this.allWork = this.props.data.workCategory.work
        break
      case `brand`:
        this.allWork = this.props.data.brand.work
        break
      case `office`:
        this.allWork = this.props.data.office.work
        break
      case `classics`:
        this.allWork = this.props.data.allWorkGreatestHits
        break
      case `design`:
        this.allWork = this.props.data.allWorkDesign
        break
      default:
        this.allWork = {}
        break
    }
  }

  componentDidMount() {
    this.props.invertHeader()
  }

  componentWillUnmount() {
    this.props.resetHeader()
  }

  ContentFiltersComponent = () => (
    <ContentFilters
      rootURL={`/work/`}
      indexType={`work`}
      workCategories={this.props.data.allWorkCategories}
      brands={this.props.data.allBrands}
      offices={this.props.data.allOffices}
      classics={true}
      workDesign={true}
      archiveType={
        this.props.pageContext.archiveType
          ? this.props.pageContext.archiveType
          : ``
      }
    />
  )

  render() {
    return (
      <Layout>
        <SEO title={`Work`} description={`A curated selection of client projects from around the world.`} />
        <div>
          <WorkList
            ContentFilters={this.ContentFiltersComponent}
            allWork={this.allWork}
            category={this.workCategoryName}
          />
        </div>
      </Layout>
    )
  }
}

export default connect(
  null,
  {
    invertHeader,
    resetHeader,
  }
)(WorkArchive)

export const query = graphql`
  query WorkArchive($slug: String!) {
    workCategory: contentfulWorkCategories(slug: { eq: $slug }) {
      id
      node_locale
      name
      slug
      work {
        ...workFragmentBase
      }
    }

    brand: contentfulClients(slug: { eq: $slug }) {
      id
      node_locale
      brandName
      slug
      work {
        ...workFragmentBase
      }
    }

    office: contentfulOffices(slug: { eq: $slug }) {
      id
      node_locale
      officeName
      slug
      work {
        ...workFragmentBase
      }
    }

    allBrands: allContentfulClients(
      sort: { fields: [brandName], order: ASC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          id
          node_locale
          brandName
          slug
          work {
            ...workFragmentBase
          }
        }
      }
    }

    allWorkCategories: allContentfulWorkCategories(
      sort: { fields: [name], order: ASC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          id
          node_locale
          name
          slug
          work {
            ...workFragmentBase
          }
        }
      }
    }

    allOffices: allContentfulOffices(
      sort: { fields: [order], order: ASC }
      filter: { node_locale: { eq: "en-US" }, venture: { eq: false } }
    ) {
      edges {
        node {
          ...officeFragment
        }
      }
    }

    allWorkGreatestHits: allContentfulWork(
      sort: { fields: [sticky], order: ASC }
      filter: { node_locale: { eq: "en-US" }, greatestHits: { eq: true } }
    ) {
      edges {
        node {
          ...workFragmentBase
        }
      }
    }

    allWorkDesign: allContentfulWork(
      sort: { fields: [sticky], order: ASC }
      filter: { node_locale: { eq: "en-US" }, workDesign: { eq: true } }
    ) {
      edges {
        node {
          ...workFragmentBase
        }
      }
    }
  }
`
